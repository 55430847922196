<script setup lang="ts">
import {
	type BrandsResponse,
	type DropsResponse,
	type EntityData,
	mapBrandsToLinkNodes,
} from '@laam/cms-shared';
import EntityCard from './EntityCard.vue';
import { computed, inject, ref, type Ref } from 'vue';

interface Props {
	metadata: EntityData;
	data: DropsResponse | BrandsResponse;
	sectionIndex: number;
}

const props = defineProps<Props>();

const output = computed(() => {
	if ('items' in props.metadata.input) {
		// check if data is link nodes array
		if (!Array.isArray(props.data)) {
			console.log('EntityCarouselScrollable: data is not an array', {
				...props,
			});
		}
		return mapBrandsToLinkNodes(
			props.data as BrandsResponse,
			props.metadata as EntityData,
		);
	} else return [];
});

const deviceType = inject('deviceType') as Ref<string | undefined>;

const entityCarouselRef = ref();
</script>

<template>
	<div class="gap-3xl lg:gap-7xl lg:p-0 p-3xl pb-0 flex flex-col items-center">
		<Carousel
			ref="entityCarouselRef"
			class="cms--carousel-entities-scrollable w-full"
			:items-to-show="deviceType === 'mobile' ? 4.4 : 7"
			snap-align="start"
		>
			<Slide
				v-for="(card, horizontalIndex) in output"
				:key="horizontalIndex"
				:index="horizontalIndex"
			>
				<slot
					:entity="props.metadata.entity"
					:data="card"
					:index="horizontalIndex"
				>
					<EntityCard
						:entity="metadata.entity"
						:data="card"
						:index="horizontalIndex"
						shape="square"
						class="pr-md"
					/>
				</slot>
			</Slide>
			<template #addons>
				<Navigation v-if="deviceType !== 'mobile'" />
			</template>
		</Carousel>
	</div>
</template>

<style>
.cms--carousel-entities-scrollable .carousel__prev {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--carousel-entities-scrollable .carousel__next {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--carousel-entities-scrollable .cms--carousel-entities-scrollable__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--carousel-entities-scrollable .carousel__slide {
	@apply pr-md lg:pr-md items-start gap-10xl;
}

.cms--carousel-entities-scrollable .carousel__track {
	@apply gap-10xl !pl-4xl;
}

.cms--carousel-entities-scrollable .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--carousel-entities-scrollable .carousel__pagination-item {
	@apply box-border;
}

.cms--carousel-entities-scrollable .carousel__pagination-button {
	@apply p-none;
}

.cms--carousel-entities-scrollable .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--carousel-entities-scrollable .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>
